import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'lazysizes';

$(document).ready(function () {
  $(".js-toggle-menu").on("click", function(){
    $("#js-menu").toggle()
  })
})
